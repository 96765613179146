.error-modal {
    padding: 6rem 3rem;

    .title {
        margin: auto;
        text-align: center;

        h3 {
            color: rgb(201, 38, 38);
            font-size: 1rem;
            font-weight: 900;
            padding-bottom: 1rem;
        }

        img {
            width: 5rem;
            margin-bottom: 2rem;
        }
    }

    .description {
        border-top: 1px solid rgb(202, 202, 202);
        padding: 1rem 0;
        text-align: center;
    }

    .button-container {
        display: flex;
        margin-top: 1rem;
        justify-content: center;

        button {
            color: white;
            min-width: 5rem;
            background-color: black;
            border-radius: 5px;
            height: 2rem;
            padding-inline: 10px;
        }
    }
}