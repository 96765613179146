.transactions-history-purshase {
    .transactions-history-web {
        h6{
            font-size: 16px;
        }
        .title-container {
            width: 100%;
            height: 3rem;
            background-color: black;
            border-radius: 10px;
            color: white;
            
            h5 {
                margin-bottom: 0;
            }
        }
        
        table {
            border-bottom: 1px solid #9a9a9a;
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 100%;
    
            .continent-row {
                background-color: white;
                border-radius: 10px;
                height: 2rem;
                border-radius: 15px;
                color: #9a9a9a;
    
                img {
                    width: 1rem;
                    margin-inline: 10px;
                    margin-bottom: 0;
                }
            }  
            .loader{
margin: auto;            }
            tr {
                text-align: center;
    
                p {
                    color: #9a9a9a;
                    margin: 0;
                    font-weight: 500;
                    font-size: 11px;
                }
                .button-locked{
                    all: unset;
                    background-color: #e6e6e6;
                    width: 6rem;
                    text-align: center;
                    height: 2rem;
                    border-radius: 5px;
                    margin: 10px 0;
                }
                .button-unlocked{
                    all: unset;
                    background-color: #a7bd42;
                    width: 6rem;
                    text-align: center;
                    height: 2rem;
                    border-radius: 5px;
                    margin: 10px 0;
                    color: #000000;
                    cursor: pointer;
                }
                th {
                    margin: 20px 0;
                    // border-top: 1px solid #a7bd42;
                    // border-bottom: 1px solid #a7bd42;
                    // background-color: #000000;
    
                }
            }
        }
        .pagination{
            padding-top: 10px;
            .separator {
                border: 1px solid black;
                width: 1px;
                height: 1.2rem;
                padding: 0;
                margin: 0 10px;
                justify-content: center;
                align-self: center;
            }
            .btn {
                text-align: left;
                border: none;
                color: black;
                margin-top: 20px;
                margin-left: 5px;
                margin-right: 5px;
                width: 8.4rem;
                height: 3rem;
                font-size: 16px;
                font-weight: 800;
                text-decoration: none;
        
                .bi {
                    border: 1px solid;
                    border-radius: 1.5rem;
                    padding: 4px;
                    color: #ffffff;
                    background-color: #000000;
                    margin-inline: 10px;
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }
        }
        .no-transactions-found{
            padding: 105px 0;
        }
    }
}