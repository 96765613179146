
.bg-form-layout 
{
     background-image: url("../../assets/images/Header_CaizEarn_new.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; 
    min-height: 100vh;
    background-repeat: no-repeat;
    overflow-x: hidden;

  }
