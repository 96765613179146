.crosIcon {
  position: absolute;
  top: 0%;
  right: 10%;
  font-size: 40px;
}
.popUpContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 10;
  opacity: 1;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(120px);
  padding: 12px 0px;
  overflow-y: scroll;
}

.item1 {
  margin-top: 320px;
  max-width: 100%;
  padding-top: 50px;
  position: relative;
  @media screen and (min-width: 633px) {
    max-width: 90%;
  }
}
.heading {
  font-size: 40px;
  font-weight: bold;
}

.description {
  font-size: 15px;
  padding: 0px 35px;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 420px) {
    padding: 0px 35px;
    font-size: 17px;
  }
  @media screen and (min-width: 633px) {
    padding: 0px 75px;
    font-size: 19px;
  }
  @media screen and (min-width: 845px) {
    font-size: 18px;
    max-width: 900px;
    padding: 0px 105px;
  }
}

.item2 {
  margin: 90px 0px 0px 0px;
  display: grid;
  grid-template-columns: auto;
  gap: 12px;
  width: 100%;
  padding: 0px 20px;
  div {
    height: 70px;
    border-radius: 13px;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    padding: 0px 20px;
    @media screen and (min-width: 845px) {
      width: 320px;
    }
  }
  @media screen and (min-width: 845px) {
    gap: 22px;
    margin-bottom: 20px;
    place-items: center;
    justify-content: center;
    grid-template-columns: auto auto;
  }
}
.popUpHidden {
  display: none;
}
//remove scrollbar
::-webkit-scrollbar {
  display: none;
}
