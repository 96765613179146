.container-fluid {
  max-width: 1130px;
  padding: 0 60px;
  margin: 0 auto;
}

.submenu-container {
  padding: 30px 50px 0 50px;

  @media screen and (max-width:992px) {
    padding: 30px 5px 0 5px;
  }
}

.header-navbar {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: .4s;
}

.header-navbar {
  .head {
    border-bottom: 1px solid rgba(183, 183, 183, 0.39);
  }
}


.header-navbar {
  .head {
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, .4);
    padding: 20px 0 0;
    position: relative;
    transition: .4s;
  }

  .head {
    padding: 10px 0 0 0;
    border-bottom: unset;
  }
}

.header-navbar {
  .head {
    #logo {
      transition: .4s;
    }

    #logo {
        max-width: 240px;
    }
  }
}

.header-navbar {
  #logo {
    img {
      max-width: 55%;
    }
  }
}

.header-navbar {
  #logo {
    img {
      -webkit-filter: initial;
      filter: initial;
    }
  }
}

.header-navbar {
  #logo {
    img {
      // -webkit-filter: brightness(0) invert(1);
      // filter: brightness(0) invert(1);
      transition: .4s;
      width: auto !important;
      height: auto !important;
    }
  }
}

.header-navbar {
  .head {
    .navbarBtn {

      width: 50px;
      height: 50px;
      padding: 2px 11px;
      cursor: pointer;
      position: relative;
      transition: .5s;
      margin: 0 0 0 auto;
      z-index: 20;
      display: none;

      @media screen and (max-width: 940px) {
        display: block;
      }

      span {
        display: block;
        background: #74d550;
        height: 2px;
        width: 30px;
        border-radius: 25px;
        margin: 7px 0;
        transition: .5s;
        position: relative;

      }

    }

    //TODO: refactor code - generic class for menu

    /* specific class for caiz menu */
    .menu {

      .opens {
        &:hover {
          .submenu-main {
            top: 55px;
            border-radius: 10px;
            @media screen and (min-width: 940px) {
              padding: 0;
              display: block;
              opacity: 1;
            }
          }
        }

        // a {
        //   background: url(../..//assets/icons/arrow-mobil.svg) no-repeat right 21px;
        //   display: inline-block;
        //   padding-right: 20px;
        // }
      }

      margin: 0;
      font-size: 16px;
      float: none;
      position: absolute;
      top: 30%;
      right: 0;
      @media screen and (min-width: 940px) {
        display: block !important;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        float: left;
        margin-top: 6px;
        margin-top: 0;

        .open {}

        li {
          &.opens {
            &:hover {
              .submenu-main {
                @media screen and (min-width: 940px) {
                  display: block;
                  opacity: 1;
                }
              }
            }

            &>a {
              @media screen and (max-width: 940px) {
                background: url(../..//assets/icons/arrow-mobil.svg) no-repeat right 1px top 26px;
                display: inline-block;
                padding-right: 20px;
              }
            }

            
          }
          &.open {
            margin-right: 10px;

          
            .submenu-main {
              @media screen and (max-width: 940px) {
                opacity: 1;
                pointer-events: initial;
                transform: translateX(0);
              }
            }
          }

          &.arrow-icon{
            margin-right: 10px;
            &:before {
              content: "\f078";
              font-size: 11px;
              margin-left: 5px;
              transition: 0.3s;
              font-family: "Font Awesome 5 Pro";
              font-weight: 300;
              color: #fff;
              position: absolute;
              top: 12px;
              right: -5px;
            }

            &:before {
              color: #fff !important;
            }

          }

          &:before {
            color: #fff !important;

            @media only screen and (max-width: 940px) {
              display: none;
            }
          }

          float: left;
          position: relative;

          a {
            // color: #4b4b4b;
            // opacity: 1;


            @media screen and (max-width: 1400px) {
              padding: 0 8px 40px;
            }

            @media screen and (max-width: 940px) {
              padding-bottom: 20px;
              color: #4b4b4b;
            }

            opacity: unset;
            color: #fff;
            font-size: 19px;
            font-family: 'ManropeEb';
            text-transform: uppercase;
            padding: 0px 12px 40px 20px;
          }


          @media screen and (max-width: 940px) {
            width: 100%;
            font-size: 23px;
          }

          a {
            color: #4b4b4b;
            opacity: 1;
          }

          a {
            display: block;
            text-decoration: none;
            color: #fff;
            padding: 0 12px 40px;

           
          }

          a {
            font-family: 'ManropeEb';
            text-transform: uppercase;
            padding: 0px 12px 40px 20px;
          }
          a{
            opacity: unset;
            font-size: 14px;
            font-family: 'ManropeEb';
            text-transform: uppercase;
            padding: 0px 12px 40px 20px;
            @media screen and (max-width: 940px) {
              opacity: unset;
              color: #000;
              font-size: 1.5rem;
              font-weight: 600;
              text-transform: uppercase;
              padding: 10px 20px;
            }

          }


          .submenu-main {
            position: absolute;
            top: 62px;
            left: 0;
            width: 100%;
            display: none;
            padding: 45px 0;
            min-width: 500px;
            background: #fff;
            z-index: -1;
            border-bottom: 1px solid rgba(183, 183, 183, .39);

            @media screen and (min-width: 1230px) { //refactor this code
              z-index: unset;
              border-radius: 0 0 15px 15px;
              width: 80vw;
              left: -434% !important;
              max-width: 1093px;
                border-bottom: unset;
              .col-md-6 {
                width: 32%;
              }
            }
            @media screen and (min-width: 1050px) and (max-width: 1229px) {
              z-index: unset;
              border-radius: 0 0 15px 15px;
              width: 80vw;
              left: -317% !important;
              max-width: 1093px;
                border-bottom: unset;
              .col-md-6 {
                width: 32%;
              }
            }

            @media screen and (min-width: 940px) and (max-width: 1049px) {
              z-index: unset;
              border-radius: 0 0 15px 15px;
              width: 80vw;
              left: -217% !important;
              max-width: 1093px;
                border-bottom: unset;
              .col-md-6 {
                width: 32%;
              }
            }

            &:before {
              border-bottom: 1px solid rgba(183, 183, 183, .39);
              content: "";
              position: absolute;
              right: 0;
              top: 1px;
              height: 100%;
              background: #fff;
              width: 100vw;
              transform: translateX(100%);
              z-index: 1;

              @media screen and (min-width: 940px) {
                top: 0px;
                height: 100.3%;
                background-color: transparent;
                border-bottom: unset;
              }
            }

            &:after {
              border-bottom: 1px solid rgba(183, 183, 183, .39);
              content: "";
              position: absolute;
              left: 0;
              top: 1px;
              height: 100%;
              background: #fff;
              width: 100vw;
              transform: translateX(-100%);
              z-index: 1;

              @media screen and (min-width: 940px) {
                background-color: transparent;
                border-bottom: unset;
              }
            }

            .back {
              display: none;

              @media screen and (max-width: 940px) {
                display: block;
                color: #06670d;
                font-size: 35px;
                padding-bottom: 10px;
              }
            }

            ul {
              list-style: none;
              padding: 0;
              margin: 0 0 0 -220px;
              position: relative;
              z-index: 3;

              li {
                width: 50%;
                font-size: 23px;
                padding: 10px 0;

                @media screen and (min-width: 940px) {
                  width: 100%;
                  font-size: 13px;
                  padding:0;
                  // padding: 10px 0;
                  float: unset;
                  margin-left: 0px;
                  // margin-bottom: 10px;
                }

                @media screen and (max-width: 940px) {
                  padding: 8px 0;
                  width: 100%;
                }

                a {
                  color: #4b4b4b;
                  opacity: 1;
                }

                a {
                  // opacity: unset;
                  // color: #fff;
                  // font-size: 19px;
                  // font-family: 'ManropeEb';
                  // text-transform: uppercase;
                  // padding: 8px 12px 40px 20px;

                  @media screen and (min-width: 940px) {
                    //padding-left: 40px;
                  }

                  @media screen and (min-width: 940px) {
                   // padding-left: 50px;
                    padding-top: 10px;
                    width: 100%;
                    font-size: 13px;
                    text-transform: uppercase;
                    margin-left: 20px;
                    display: flex;
                  }

                  @media screen and (max-width: 940px) {
                    opacity: unset;
                    color: #000;
                    font-size: 1.5rem;
                    font-weight: 600;
                    text-transform: uppercase;
                  }
                }

                a {
                  position: relative;
                  transition: .3s;
                  color: #000;
                  padding-bottom: 0;
                }

                &:before {
                  @media screen and (max-width: 940px) {
                    display: none;
                  }
                }
              }
            }

            ul {
              margin: 0;
              float: unset;
              margin-bottom: 10px;
              margin-top: 10px;
            }

            @media screen and (max-width: 1400px) {
              // top: 46px;
            }

            @media screen and (max-width: 940px) {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 5;
              padding: 55px 25px 25px;
              min-width: 100%;
              display: block;
              opacity: 0;
              pointer-events: none;
              transform: translateX(100px);
              transition: .4s;
            }

            // @media screen and (max-width: 940px) {
            //   opacity: 1;
            //   pointer-events: initial;
            //   transform: translateX(0);
            // }
          }
        }
      }

      @media screen and (max-width: 940px) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: #fff;
        margin: 0;
        padding: 75px 25px 25px;
        text-align: left;
      }
    }

    // specific class for other submenu
    .menu {

      .opens {
        &:hover {
          .submenu {
            @media screen and (min-width: 922px) {
              display: block;
              opacity: 1;
            }
          }
        }

        // a {
        //   background: url(../..//assets/icons/arrow-mobil.svg) no-repeat right 21px;
        //   display: inline-block;
        //   padding-right: 20px;
        // }
      }    
      margin: 0;
      font-size: 16px;
      float: none;
      position: absolute;
      top: 30%;
      right: 0;

      @media screen and (min-width: 940px) {
        display: block !important;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        float: left;
        margin-top: 6px;
        margin-top: 0;

        .open {}

        li {
          &.opens {
            &:hover {
              .submenu {
                top: 55px;
                border-radius: 10px;
                @media screen and (min-width: 940px) {
                  display: block;
                  opacity: 1;
                }
              }
            }

            &>a {
              @media screen and (max-width: 940px) {
                background: url(../..//assets/icons/arrow-mobil.svg) no-repeat right 1px top 26px;
                display: inline-block;
                padding-right: 20px;
              }
            }


          }

          &.open {
            margin-right: 10px;


            .submenu {
              @media screen and (max-width: 940px) {
                opacity: 1;
                pointer-events: initial;
                transform: translateX(0);
              }
            }
          }

          &.arrow-icon {
            margin-right: 10px;

            &:before {
              content: "\f078";
              font-size: 11px;
              margin-left: 5px;
              transition: 0.3s;
              font-family: "Font Awesome 5 Pro";
              font-weight: 300;
              color: #fff;
              position: absolute;
              top: 12px;
              right: -5px;
            }

            &:before {
              color: #fff !important;
            }

          }

          &:before {
            color: #fff !important;

            @media only screen and (max-width: 940px) {
              display: none;
            }
          }

          float: left;
          position: relative;

          a {
            // color: #4b4b4b;
            // opacity: 1;


            @media screen and (max-width: 1400px) {
              padding: 0 8px 40px;
            }

            @media screen and (max-width: 940px) {
              padding-bottom: 20px;
              color: #4b4b4b;
            }

            opacity: unset;
            color: #fff;
            font-size: 19px;
            font-family: 'ManropeEb';
            text-transform: uppercase;
            padding: 0px 12px 40px 20px;
          }


          @media screen and (max-width: 940px) {
            width: 100%;
            font-size: 23px;
          }

          a {
            color: #4b4b4b;
            opacity: 1;
          }

          a {
            display: block;
            text-decoration: none;
            color: #fff;
            padding: 0 12px 40px;


          }

          a {
            font-family: 'ManropeEb';
            text-transform: uppercase;
            padding: 0px 12px 40px 20px;
          }

          a {
            opacity: unset;
            font-size: 14px;
            font-family: 'ManropeEb';
            text-transform: uppercase;
            padding: 0px 12px 40px 20px;

            @media screen and (max-width: 940px) {
              opacity: unset;
              color: #000;
              font-size: 1.5rem;
              font-weight: 600;
              text-transform: uppercase;
              padding: 10px 20px;
            }

          }


          .submenu {
            position: absolute;
            top: 62px;
            left: 0;
            width: 250px;
            display: none;
            background: #fff;
            z-index: -1;
            border-bottom: 1px solid rgba(183, 183, 183, .39);

            @media screen and (min-width: 940px) {
              z-index: unset;
              border-radius: 0 0 15px 15px;
              border-bottom: unset;
            }

            @media screen and (min-width: 940px) and (max-width: 1600px) {
              top: 52px;
            }

            &:before {
              border-bottom: 1px solid rgba(183, 183, 183, .39);
              content: "";
              position: absolute;
              right: 0;
              top: 1px;
              height: 100%;
              background: #fff;
              width: 100vw;
              transform: translateX(100%);
              z-index: 1;

              @media screen and (min-width: 940px) {
                top: 0px;
                height: 100.3%;
                background-color: transparent;
                border-bottom: unset;
              }
            }

            &:after {
              border-bottom: 1px solid rgba(183, 183, 183, .39);
              content: "";
              position: absolute;
              left: 0;
              top: 1px;
              height: 100%;
              background: #fff;
              width: 100vw;
              transform: translateX(-100%);
              z-index: 1;

              @media screen and (min-width: 940px) {
                background-color: transparent;
                border-bottom: unset;
              }
            }

            .back {
              display: none;

              @media screen and (max-width: 940px) {
                display: block;
                color: #06670d;
                font-size: 35px;
                padding-bottom: 10px;
              }
            }

            ul {
              list-style: none;
              padding: 0;
              margin: 0 0 0 -220px;
              position: relative;
              z-index: 3;

              li {
                width: 50%;
                font-size: 23px;
                padding: 10px 0;

                @media screen and (min-width: 940px) {
                  width: 250px;
                  font-size: 13px;
                  padding: 0;
                  float: unset;
                  margin-left: 0px;
                }

                @media screen and (max-width: 940px) {
                  padding: 8px 0;
                  width: 100%;
                }

                a {
                  color: #4b4b4b;
                  opacity: 1;
                }

                a {
                  // opacity: unset;
                  // color: #fff;
                  // font-size: 19px;
                  // font-family: 'ManropeEb';
                  // text-transform: uppercase;
                  // padding: 8px 12px 40px 20px;

                  @media screen and (min-width: 940px) {
                    //padding-left: 40px;
                  }

                  @media screen and (min-width: 940px) {
                    // padding-left: 50px;
                    padding-top: 10px;
                    width: 100%;
                    font-size: 13px;
                    text-transform: uppercase;
                    margin-left: 20px;
                    display: flex;
                  }

                  @media screen and (max-width: 940px) {
                    opacity: unset;
                    color: #000;
                    font-size: 1.5rem;
                    font-weight: 600;
                    text-transform: uppercase;
                  }
                }

                a {
                  position: relative;
                  transition: .3s;
                  color: #000;
                  padding-bottom: 0;
                  padding: 0;
                  margin: 0;
                }

                &:before {
                  @media screen and (max-width: 940px) {
                    display: none;
                  }
                }
              }
            }

            ul {
              margin: 0;
              float: unset;
              margin-bottom: 10px;
              margin-top: 10px;
            }

            @media screen and (max-width: 1400px) {
              top: 46px;
            }

            @media screen and (max-width: 940px) {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 5;
              padding: 55px 25px 25px;
              min-width: 100%;
              display: block;
              opacity: 0;
              pointer-events: none;
              transform: translateX(100px);
              transition: .4s;
            }

            // @media screen and (max-width: 940px) {
            //   opacity: 1;
            //   pointer-events: initial;
            //   transform: translateX(0);
            // }
          }
        }
      }

      @media screen and (max-width: 940px) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: #fff;
        margin: 0;
        padding: 75px 25px 25px;
        text-align: left;
      }
    }
  }
}

/* Base Class for Common Styles */
.cool-link-base {
  display: inline-block;
  text-decoration: none;
  text-transform: none;
  text-align: left;

  &:hover {
    &:after {
      width: 100%;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    transition: width .3s;
  }
}

/* Specific Class for Main Link - menu heading */
.cool-mainlink { 
  @extend .cool-link-base;
  font-size: 16px;
  &:after {
    background: linear-gradient(to right, #006400, #5EC14A);
  }
}

/* Specific Class for Menu Links - caiz sub menu */
.cool-link-menu {
  @extend .cool-link-base;
  font-size: 14px;
  // padding: 10px 0px;
  color: #495057;
  &:hover {
    border-radius: 5px;
    background-color: #F2F2F2;
    color: #000;
    width: 92%;
  }
}

/* Specific Class for Other Links - other sub menu  */
.cool-link {
  @extend .cool-link-base;
  font-size: 14px;
  // padding: 10px 0px;
  color: #495057;
  &:hover {
    border-radius: 5px;
    background-color: #F2F2F2;
    color: #000;
    width: 100%;
  }
}


@media screen and (min-width: 940px) {
  .sub-col-rb {
    position: relative;
  }
}

/* menu icon class */
.icon-style {
  color: #495057;
  margin-right: 10px;
  padding-left: 5px;
}
img.icon-menu-style{
  margin-right: 10px;
}
img.icon-arrow {
  float: right;
}

/* hiring link class */
.header-hiring{
  background-color: #d9e143;
  border-radius: 5px;
  padding: 10px;
  @media screen and (max-width: 940px) {
    margin-left: -7px;
  }
}

/* Join Caiz button class  */
.join-caiz-button {  
  background-color: #74d550;
  padding: 9px 18px 9px 18px;
  color: #000;
  border: none;
  padding: 10px 18px;
  margin: -10px 0px;
  font-size: 16px;
  border-radius: 4px;
  border: 0 solid transparent;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-weight: bold !important;
}

button.join-caiz-mobile-button {
  background-color: rgb(116, 213, 80);
  color: rgb(0, 0, 0);
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  margin-bottom: 25px;
  width: 80%;
  text-align: center;
  display: block;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  cursor: pointer;
  border: none;
}

/* close and open icon class  */
.closeItems {
  display: none;
}
.openItems {
  display: block;
}

/* social media class  */
.social-icons-container {
  display: grid; //refactor this line
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  align-items: center;
}

.social-icon {
  box-sizing: border-box;
  text-align: center;
}

/* mobile menu class  */
.animatedNavbar {
  background-color: black;
  height: 100vh;
  color: white;
  position: fixed;
  width: 100%;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  animation: slideIn 0.4s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.animatedNavbarClose {
  display: none;
}
.animatedNavbarItems {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  a,
  p {
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
    color: white;
    display: block;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    padding: 11px 0px;
  }
  a:active,
  a:focus,
  a:hover {
    color: #74d550 !important;
  }
  button {
    background-color: rgb(116, 213, 80);
    color: rgb(0, 0, 0);
    border-radius: 5px;
    padding: 10px 20px;
    width: 80%;
    text-align: center;
    display: block;
    margin-top: 20px;
    margin-bottom: 25px;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
  }
  .socialMediaText {
    width: 300px;
    text-align: left;
    margin-left: 12;
    font-size: 20px;
    border-bottom: 2px solid white;
    padding: 0px 0px 18px 0px;
  }
}

.animatesideLinkArea1,
.animatesideLinkArea2,
.animatesideLinkArea3 {
  display: flex;
  flex-direction: column;
  visibility: visible;
  align-items: start;
  padding: 0px 3px;
  animation: slideFromRight 0.3s ease-in-out;
  a,
  p {
    text-decoration: none;
    color: white;
    font-size: 18px;
    margin-top: 12px;
    font-weight: bold;
  }
  a:active,
  p:active {
    text-decoration: none;
    color: white;
    font-size: 18px;
    margin-top: 12px;
    font-weight: bold;
  }
}
.animatesideLinkAreaHeader {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-size: 20px;
  width: 180px;
  opacity: 0.4;
  align-items: center;
  border-bottom: 2px solid white;
  padding-bottom: 8px;
  justify-content: center;
  .arrowicon {
    width: 30px;
  }
  div {
    width: 100%;
    text-align: left;
    margin-left: 5px;
  }
  &:hover {
    color: #74d550 !important;
    opacity: 1;
  }
}
/* mobile: sub menu items link class */
span.foInter.animatesideAreaLink { //refactor this line
  color: white !important;
}
span.foInter.animatesideAreaLink:hover {
  color: #74d550 !important;
  opacity: 1;
} 
.navbarListItemOpen {
  display: block;
  animation: firstElementOpen 0.3s ease-in-out;
  width: 100%;
  padding: 10px 10px 10px 15px;
}
.navbarListItemClose {
  opacity: 0;
  display: none;
  animation: slideFromLeft 0.3s ease-in-out;
}
.animatesideLinkAreaClose1,
.animatesideLinkAreaClose2,
.animatesideLinkAreaClose3 {
  animation: sideLinkAreaAnimation 0.3s ease-in-out;
  display: none;
  flex-direction: column;
  visibility: visible;
  align-items: start;
  padding: 0px 3px;
  a,
  p {
    text-decoration: none;
    color: white;
    font-size: 18px;
    margin-top: 12px;
    font-weight: bold;
  }
  a:active,
  p:active {
    text-decoration: none;
    color: white;
    font-size: 18px;
    margin-top: 12px;
    font-weight: bold;
  }
}
@media screen and (min-width: 940px) {
  .smallNavHamburger {
    display: none;
  }
}
@media screen and (max-width: 521px) {
  .animatedNavbar {
    padding: 0px 20px;
  }
  .animatedNavbarItems {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 0px 12px;

    button {
      font-size: 18px;
    }
    .socialMediaText {
      width: auto;
      text-align: left;
      margin-left: 12;
      font-size: 20px;
      border-bottom: 2px solid white;
      padding: 0px 0px 8px 0px;
      width: 300px;
    }
  }
  .animatesideLinkArea1,
  .animatesideLinkArea2,
  .animatesideLinkArea3 {
    width: 100%;
  }
  .animatesideLinkAreaHeader {
    width: 200px;
  }
}

/* animation class */
@keyframes firstElementOpen {
  from {
    transform: translateX(-40%);
    // opacity: 0;
  }
  to {
    // opacity: 0.1;
    transform: translateX(0%);
  }
}
@keyframes sideLinkAreaAnimation {
  from {
    transform: translateX(-40%);
    opacity: 0.1;
    display: flex;
  }
  to {
    display: none;
    opacity: 0;
    transform: translateX(0);
  }
}
@keyframes slideFromRight {
  from {
    transform: translateX(40%);
    opacity: 0.1;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideFromLeft {
  from {
    transform: translateX(20%);
    opacity: 1;
    display: block;
  }
  to {
    display: none;
    opacity: 0;
    transform: translateX(0%);
  }
}
@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
/* cross icon class */
.svgCross {
  width: 40px;
  left: 20px;
  position: absolute;
  top: 16px;
  color: #CCCCCC; 
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  border-radius: 5px;
}
/* Hamburger icon class */
.smallNavHamburger {
  width: 50px;
  height: 50px;
  padding: 2px 11px;
  cursor: pointer;
  position: absolute;
  top: 2%;
  right: 2%;
  transition: 0.5s;
  span {
    display: block;
    background: #74d550;
    height: 2px;
    width: 30px;
    border-radius: 25px;
    margin: 7px 0;
    transition: 0.5s;
    position: relative;
  }
}

