.footer-bottom {
  .container-fluid.ps-3.ps-3 { //refactor this line
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  @media screen and (min-width: 992px) {
    .container-fluid.ps-3.ps-3 {
      display: block;
    }
  }
  background-color: #F8F9FA;
  color: #757575;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px dotted #e9ecef !important;
  @media screen and (min-width: 400px) {
    padding: 0;
  }  
  @media screen and (min-width: 992px) {
    padding: 30px 20px;
  }
  .row {
    width: 100%;
    &.social-media-row {
      @media screen and (max-width: 401px) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .social-media {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F8F9FA;
      @media screen and (min-width: 400px) {
          padding: 15px 0;
      }
      a {
        img {
          width: auto;
          margin-inline: 2px;
          width: 25px;
          height: 25px;
        }
      }
      img.whatsapp.lazy { //refactor this line
        width: 18px !important;
        height: 18px !important;
      }
    }

    .partners {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F8F9FA;
      padding-left: 15px;
      padding-right: 15px;

      @media screen and (min-width: 992px) {
        .partners-multiple-images {
          display: flex !important;
          justify-content: flex-start;
        }
        img.multi-partners-logo{
          padding-left: 10px;
          padding-right: 10px;
          width: 25% !important;
        }
        }
      @media screen and (min-width: 992px) {
        margin-left: 120px;
      }
      .partners-single-image {
        display: none;
      }
      .partners-multiple-images {
        margin-left: 15px;
        margin-right: 15px;
      }
      .multi-partners-logo{
        width: 20%;
        max-width: 100px;  
      }
      @media screen and (max-width: 401px) {
        .partners-single-image {
            display: block;
        }
        .single-partners-logo{
          width: 85%;    
        }
        .partners-multiple-images {
            display: none;
        }
      }

      img {
        padding-left: 15px;
        padding-right: 15px;    
      }
    }  

    .row.site-links-row { //refactor this line
      @media screen and (max-width: 401px) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }  
    .site-links {
      flex-direction: row;
      padding: 20px 20px;
      @media screen and (min-width: 400px) {
          padding: 20px 0px;
        }   
      }
      a {
        align-items: center;
        text-decoration: none;
        font-size: 13px;
        padding: 5px 0px;
        margin-right: 10px;
        @media screen and (min-width: 400px) {
          align-items: center;
          text-decoration: none;
          font-size: 14px;
        }
        img {
          width: 25px;
          height: 25px;       
          margin-right: 0.25rem!important;
          @media screen and (max-width: 992px) {
            width: 20px;
            height: 20px;
          }    
          }
          span {
            color: #000!important;
            //hover effect start
            position: relative;
            display: inline-block;
            font-family: "Open Sans", sans-serif; //refactor this line
            @media screen and (min-width: 400px) {
              padding-bottom: 10px;
            }
            &:hover {
                &:after {
                    width: 100%;
                }
            }
            &:after {
                content: '';
                display: block;
                width: 0;
                height: 4px;
                background: #74d550; 
                transition: width .3s;
            }    
          }
        }
    }

    .copyright {
      display: flex;
      justify-content: center;
      align-items: center;      
      font-size: 14px;
      @media screen and (min-width: 992px) {
        display: flex;        
        justify-content: right;
      }
      @media screen and (max-width: 991px) {
        text-align: center;
        padding: 10px;
        background-color: #090a0a;
      }
        p {
          color: #000!important;
          margin: 0;
          @media screen and (max-width: 992px) {
            color: white!important;
            font-size: 12px;
            padding-left: 0;
          }
        }        
    } 
  
  }

//child
.footer-bottom-modified {
  .container-fluid.ps-3.ps-3 { //refactor this line
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  @media screen and (min-width: 992px) {
    .container-fluid.ps-3.ps-3 {
      display: block;
    }
  }
  @extend .footer-bottom;
  background-color: #f8f9fa;
  @media screen and (max-width: 400px) {
      padding: 0;
    }  
  }


