.form-screen {
  padding: 16rem 0 0 0;

  /* Works for Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Works for Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  @media screen and (max-width: 520px) {
    img {
      width: 26rem;
    }
  }

  @media screen and (min-width: 521px) {
    img {
      width: 36rem;
    }
  }

  .row {
    display: flex;
    padding: 0 0 3rem 0;

    .description {
      height: 30rem;
      background-color: #d9e143;
      border-radius: 10px;
      padding: 8rem 5rem 5rem 5rem;

      @media screen and (min-width: 1601px) {
        width: 40%;

        strong {
          font-weight: 900;
          font-size: 3.125rem;
        }

        h1 {
          line-height: 3rem;
          text-align: start;
          font-size: 3.125rem;
        }
      }

      @media screen and (min-width: 1400px) and (max-width: 1600px) {
        width: 38%;

        strong {
          font-weight: 900;
          font-size: 3rem;
        }

        h1 {
          line-height: 3rem;
          text-align: start;
          font-size: 3rem;
        }
      }

      @media screen and (min-width: 1250px) and (max-width: 1399px) {
        width: 40%;

        strong {
          font-weight: 900;
          font-size: 2.7rem;
        }

        h1 {
          line-height: 3rem;
          text-align: start;
          font-size: 2.7rem;
        }
      }

      @media screen and (min-width: 1200px) and (max-width: 1249px) {
        width: 41%;

        strong {
          font-weight: 900;
          font-size: 2.7rem;
        }

        h1 {
          line-height: 3rem;
          text-align: start;
          font-size: 2.7rem;
        }
      }

      @media screen and (min-width: 1090px) and (max-width: 1199px) {
        width: 45%;

        strong {
          font-weight: 900;
          font-size: 2.5rem;
        }

        h1 {
          line-height: 3rem;
          text-align: start;
          font-size: 2.5rem;
        }
      }

      @media screen and (min-width: 992px) and (max-width: 1089px) {
        width: 45%;

        padding: 8rem 6rem 10rem 6rem;

        strong {
          font-weight: 900;
          font-size: 2.5rem;
        }

        h1 {
          line-height: 3rem;
          text-align: start;
          font-size: 2.5rem;
        }
      }

      @media screen and (max-width: 991px) {
        width: 100%;
        height: fit-content;

        strong {
          font-weight: 900;
          font-size: 3.125rem;
        }

        h1 {
          line-height: 3rem;
          text-align: start;
          font-size: 3.125rem;
        }
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        padding: 4rem;
        height: fit-content;

        strong {
          font-weight: 900;
          font-size: 2rem;
        }

        h1 {
          line-height: 3rem;
          text-align: start;
          font-size: 2rem;
        }
      }

      .arrow-container {
        position: relative;
        // z-index: 2;
        background-color: white;
        display: flex;

        @media screen and (min-width: 1670px) {
          width: 36rem;
        }

        @media screen and (min-width: 1460px) and (max-width: 1669px) {
          width: 35rem;
        }

        @media screen and (min-width: 1320px) and (max-width: 1459px) {
          width: 31rem;
        }

        @media screen and (min-width: 1100px) and (max-width: 1319px) {
          width: 27rem;
        }

        @media screen and (min-width: 992px) and (max-width: 1099px) {
          width: 24rem;
        }

        @media screen and (min-width: 601px) and (max-width: 991px) {
          img {
            width: 5rem;
            margin-left: 2rem;
            rotate: 90deg;
          }
        }

        @media screen and (max-width: 600px) {
          width: 100%;
          height: fit-content;
          padding: 0;
          img {
            width: 3rem !important;
            margin-right: 1rem !important;
            rotate: 90deg;
          }
        }

        justify-content: end;
        border-radius: 10px;
        box-shadow: rgba(118, 124, 131, 0.2) 0px 8px 24px;
        padding-inline: 3rem;
        margin: 2rem 0;

        strong {
          font-weight: 900;
          font-size: 1.2rem;
        }

        h3 {
          margin-bottom: 0;
          padding: 1rem 0;
          font-size: 1.2rem;
        }

        img {
          width: 5rem;
          margin-left: 2rem;
        }
      }
    }

    .form-container {
      form {
        padding: 14rem 10rem 0 10rem;

        @media screen and (min-width: 1300px) and (max-width: 1400px) {
          padding: 14rem 7rem 0 7rem;
        }

        @media screen and (min-width: 1201px) and (max-width: 1299px) {
          padding: 14rem 4rem 0 4rem;
        }

        @media screen and (min-width: 1100px) and (max-width: 1200px) {
          padding: 14rem 3rem 0 3rem;
        }

        @media screen and (min-width: 992px) and (max-width: 1099px) {
          padding: 14rem 1rem 0 1rem;
        }

        @media screen and (min-width: 701px) and (max-width: 991px) {
          padding: 5rem 3rem;
        }

        @media screen and (max-width: 700px) {
          padding: 5rem 1rem;
        }

        .error {
          margin-top: 10px;
          background-color: rgb(241, 161, 161);
          border-radius: 5px;
          min-height: 2rem;
          padding: 10px;
          width: 100%;

          .bi {
            font-size: 20px;
          }
        }

        p {
          color: #9a9a9a;

          strong {
            color: black;
          }
        }

        .input-container {
          justify-content: start;
          text-align: start;

          .input-fields {
            align-items: start;
            justify-content: start;
            border-radius: 10px;
            margin: 1rem 0;

            label {
              font-weight: 900;
              font-size: 1.2rem;
              padding: 1rem 0;
            }

            h6 {
              font-size: 1rem;
              font-weight: 900;
              margin-bottom: 0;
            }

            .max-amount-btn {
              background-color: black;
              color: white;
              justify-content: center;
              border-radius: 5px;
              border: none;
              padding-inline: 10px;
              margin-inline: 10px;
            }

            input {
              width: 100%;
              background-color: #ffffff;
              border: none;
              border-radius: 5px;
              padding-inline: 10px;
              height: 3rem;
              font-size: 1rem;
            }

            .bi {
              position: relative;
              top: 10px;
              left: 45px;
              font-size: 20px;
              color: #9a9a9a;
            }
          }

          .legal-disclaimer {
            strong {
              color: #9a9a9a;
            }

            padding-top: 20px;
            margin-top: 20px !important;
            color: #9a9a9a;
            margin: 0;
            font-weight: 500;
            font-size: 1rem;
          }

          .checkbox-container {
            label {
              font-weight: 500;
              font-size: 16px;
            }

            a {
              text-decoration: none;
              color: #d9e143;
            }
          }
        }

        .btn {
          background-color: #d9e143 ;
          color: black;
          display: flex;
          justify-content: center;
          font-weight: 900;
          margin: 1rem 0;
          border-radius: 5px;
          min-height: 2.5rem;
          width: 100%;
          border: none;

          h6 {
            margin: 0;
          }
        }

        .btn-disabled {
          background-color: #9a9a9a;
          color: white;
          display: flex;
          justify-content: center;
          font-weight: 900;
          align-items: center;
          margin: 1rem 0;
          border-radius: 5px;
          min-height: 2.5rem;
          width: 100%;
          border: none;

          h6 {
            margin: 0 !important;
          }
        }
      }
    }
  }
}
