.mobile-restriction {
  padding: 5rem 4rem;
  text-align: center;
  .bi-laptop {
    font-size: 6rem;
  }
  h3 {
    margin-top: 2rem;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1rem;
  }
}
