//divider

.topSvg {
  position: absolute;
  left: 0;
  top: 0;
  border: none;
  bottom: 10px;
  margin-top: -3px;
}
.bottomSvg {
  position: absolute;
  border: none;
  left: 0;
  bottom: 0;
  rotate: 180deg;
  margin-bottom: -3px;
}

.button-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #74d550;
  color:#2d2d2d;
  text-decoration: none;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: #2d2d2d;
  }
}
//section1

.section1 {
  padding: 5% 0 10% 0;
  background-color: #2d2d2d;
  // height: 100vh;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  margin-top: 60px;
  // background-color: aqua;
  // background-color: #74d550;
}

.hero-contain {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: start;
  // background-color: red;

  h1 {
    color: #74d550;
    font-family: 'Inter', sans-serif;
    font-weight: 700 !important;
    font-size: 55px; //refactor
  }
}
.vizeble-text {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 16px;
  line-height: 30px;
  padding-top: 10px;
  font-family: 'Open Sans', sans-serif;
  // width: 60%;
  // background-color: aqua;
}

.hero-image {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  // max-width: 100% !important;
  // background-color: #2d2d2d;
}

.hero-bos {
  position: relative;
}

// divider

.clip-pathpoint {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -75;

  // background-color: #2d2d2d;
}

svg {
  vertical-align: middle;
}

.hidden-text {
  display: none;
}

@media screen and (max-width: 1200px) {
  .hero-image {
    width: 50%;
  }
}

@media screen and (max-width: 990px) {
  .hero-contain {
    h1 {
      font-size: 35px;
      max-width: 400px;
    }
  }
  .vizeble-text {
    width: auto;
  }

  .hero-image {
    width: 40%;
  }
}

@media screen and (max-width: 770px) {
  .hero {
    flex-direction: column;
    gap: 0;
  }

  .hero-image {
    width: 100%;
  }

  .hero-contain {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      width: 100%;
      padding-right: 0px;
      font-size: 28px;
    }
  }
  .vizeble-text {
    display: none;
  }

  .hidden-text {
    display: block;
    max-width: 550px;
  }
}

@media screen and (max-width: 575px) {
  .hidden-text {
    width: auto;
    padding: 13px;
    font-size: 14px;
  }

  .hero {
    margin-top: 80px;
  }

  .hero-contain {
    h1 {
      font-size: 30px;
    }
  }
}

// section2

.section2 {
  padding: 5% 5px 10% 5px;
  background-color: #f8f9fa;
}

.sec2-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  h2 {
    color: #495057;
    font-weight: 700;
    font-size: 48px;
    font-family: 'Inter', sans-serif;
    max-width: 600px;
  }
  p {
    color: #495057;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
  }
}

.badgemain {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding-top: 30px;
  // max-width: 1200px;
}

.budge-box {
  background-color: white;
  display: flex;
  align-items: center;
  width: 500px;
  border-radius: 20px;
  box-shadow: 0px 3px 29px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
}
.budge-box:hover {
  box-shadow: 0px 16px 29px 0px rgba(0, 0, 0, 0.1);
  transform: translateY(-6px);
}

.badimage {
  width: 250px;
  height: 250px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.budgetext {
  text-align: start;
  max-width: 35%;
  padding-left: 20px;

  div {
    max-width: 60%;
    font-family: sans-serif; //refactor
    font-size: 13.5px;
    background-color: #dee2e6;
    padding: 10px;
    border-radius: 5px;
    color: #495057 !important;
    font-weight: 400 !important;
    @media screen and (max-width: 1030px) {
      max-width: 100%;
    }
  }

  h3 {
    font-family: 'Inter', sans-serif;
    font-size: 30px; //refactor
    margin-top: 20px;
    color: #495057 !important;
    font-weight: 700 !important;
  }
}

@media screen and (max-width: 1200px) {
  .sec2-text {
    h2 {
      max-width: 50%;
    }
  }

  .badimage {
    width: 200px;
  }
}

@media screen and (max-width: 990px) {
  .sec2-text {
    h2 {
      max-width: 50%;
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 750px) {
  .budgetext {
    padding-left: 20px;
    max-width: 40%;
  }

  .badimage {
    width: 150px;
  }
}

@media screen and (max-width: 575px) {
  .sec2-text {
    h2 {
      max-width: 90%;
    }
  }

  .badgemain {
    flex-direction: column;
    margin: auto;

    justify-content: center;
    align-items: center;
  }

  .budge-box {
    flex-direction: column;
    justify-content: center;
    width: 300px;
    height: auto;
  }

  .badimage {
    width: 300px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;
  }

  .budgetext {
    padding: 30px 0px;
    text-align: center;

    h3 {
      font-size: 24px;
      // margin-top: 0;
    }
  }
  // divider
  // .divider2 {
  //   margin-top: -2px;
  // }
  // .divider2-bottom {
  //   margin-bottom: -2px;
  // }
}

// section3

.section3 {
  padding: 5% 0 5% 0;
  background-color: #2d2d2d;
  margin-bottom: 30px;
  position: relative;
  div {
    margin: 60px 0px;
  }
}
// .link-icon {
//   width: 13%;
// }

.tokens-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  // background-color: red;

  h1 {
    max-width: 400px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 48px;
    color: #74d550;
  }

  div {
    font-family: 'Open Sans', sans-serif;
    background-color: #74d550;
    color: #2d2d2d;
    border-radius: 5px;
    padding: 10px 0px; //refactor
    text-align: center;
    border: none;
    font-weight: 700 !important;
    font-size: 14px;
    cursor: pointer;
    width: 150px; //refactor
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .coin-icon {
    padding-right: 5px;
    transition: all 0.5s ease-in-out;
    font-size: 30px;
  }
}
.coin-icon:hover {
  transform: translatex(-6px);
}

@media screen and (max-width: 990px) {
  .tokens-sec {
    h1 {
      font-size: 28px;
      font-weight: normal !important;
    }
  }
}

// section4

.section4 {
  padding: 0 0 10% 0;
}

.sec4-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; //refactor
  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    color: #495057;
    max-width: 400px;
    @media screen and (max-width: 400px) {
      font-size: 28px;
    }
  }
}

.benefit-icon {
  display: flex;
  align-items: end;
  gap: 5px;

  h6 {
    font-weight: 700 !important;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #495057;
  }
  // img {
  //   width: 10%;
  // }
}

.benefit-text {
  text-align: start;
  color: #2d2d2d !important;
  padding-top: 20px;
  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 700;
    text-align: left;
    color: #495057;
    @media screen and (max-width: 400px) {
      font-size: 28px;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    text-align: left;
    color: rgba(6, 6, 6, 0.5) !important;
  }
}

.benefitbox {
  width: 500px;
  padding: 50px;
  border-radius: 20px;
}

.benefit-icons {
  width: 40px;
}

.benefit-flexbox {
  display: grid;
  grid-template-columns: auto auto;
  place-content: center;
  padding-top: 70px;
  gap: 30px;
}

.benefitboxcolor {
  background-color: #f8f9fa;
}

@media screen and (max-width: 990px) {
  .benefitbox {
    max-width: 400px;
  }
}

@media screen and (max-width: 780px) {
  .benefitbox {
    max-width: 350px;
  }

  .benefit-flexbox {
    gap: 10px;
  }
}

@media screen and (max-width: 700px) {
  .benefit-flexbox {
    grid-template-columns: auto;
    place-items: center;
  }

  .benefitbox {
    max-width: 80%;
  }
}

@media screen and (max-width: 400px) {
  .benefitbox {
    max-width: 70%;
  }
}

@media screen and (max-width: 350px) {
  .benefitbox {
    max-width: 60%;
  }
}

// divider
// .divider5-bottom {
//   position: absolute;
//   bottom: 0px;
// }

// section5

.section5 {
  height: 100vh;
  background-color: rgb(45, 45, 45);
  margin-top: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  position: relative;
  .popUpVideoContainer {
    position: relative;
    width: 100%;
    background-image: url("../../assets/caiz-earn-page/CaizEarnIpadVideo.png");
    border-radius: 20px;
    height: 300px;
    background-color: rgb(26, 23, 23);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px 0px;
    .playButton {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: white;
      top: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in;
      // .playButtonIcon { //remove
      //   color: green;
      //   font-size: 30px;
      // }
    }
    .playButton:hover {
      width: 120px;
      height: 120px;
      transition: all 0.3s ease-in;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    img {
      width: 80%;
    }
    @media screen and (min-width: 570px) {
      max-width: 540px;
      height: 300px;
    }
    @media screen and (min-width: 990px) {
      max-width: 700px;
      height: 400px;
    }
  }
  .sec5PopUpVideo {
    position: fixed;
    padding: 0px 20px;
    z-index: 10;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(rgba(122, 117, 120, 0.3), #333), transparent;
    div {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: end;
      .crossIconContainer {
        width: 400px;
        .crossIcon {
          width: 40px;
          color: white;
        }
      }
      .popVideo {
        width: 350px;
        @media screen and (min-width: 480px) {
          width: 400px;
        }
        @media screen and (min-width: 614px) {
          width: 600px;
        }
      }
    }
  }
}
.popVideoClose {
  display: none;
}

// section6

.section6 {
  background-color: rgb(248, 248, 248);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 120px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  .sec6Item1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    flex-direction: column;
    .insutruction {
      font-family: 'Inter', sans-serif;
      font-size: 13.5px;
      font-weight: 700;

      width: 140px;
      padding: 2px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      background-color: black;
      color: white;
      // font-weight: 700;
    }
    h4 {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 48px; //refactor
      max-width: 240px;
      margin-top: 20px;
      color: #495057;
      // text-align: left;
      @media screen and (max-width: 400px) {
        font-size: 26px;
      }
    }
    p {
      font-family: 'Open Sans', sans-serif;
      text-align: left;
      color: #495057;
      font-size: 16px;
      max-width: 300px;
      // font-weight: 700px;
    }
    @media screen and (min-width: 768px) {
      justify-content: start;
      text-align: left;
      align-items: start;
      h4 {
        font-weight: 700;
        font-size: 30px;
        max-width: 340px;
        margin-top: 20px;
      }
    }
    @media screen and (min-width: 1000px) {
      justify-content: start;
      text-align: left;
      align-items: start;
      h4 {
        font-weight: 700;
        font-size: 48px;
        max-width: 440px;
        margin-top: 20px;
      }
    }
  }
  .sec6Item2 {
    padding: 0px 10px;
    .mergeItems {
      border: 2px solid white;
      cursor: pointer;
      border-radius: 10px;
      padding: 20px 10px 20px 20px;
      display: flex;
      gap: 12px;
      justify-content: start;
      background-color: white;
      max-width: 100%;
      margin: 10px auto;
      .Nuuber {
        font-family: 'Inter', sans-serif;
        font-size: 27px; //refactor
        font-weight: 700;
        text-align: center;
        background-color: black;
        width: 50px;
        height: 50px;
        color: white;
        display: flex;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        @media screen and (min-width: 990px) {
          width: 64px;
          height: 57px;
          font-size: 20px;
        }
      }
      .desc {
        text-align: left;
        max-width: 280px;
        display: flex;
        padding-top: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 570px) {
          max-width: 250px;
        }

        @media screen and (min-width: 990px) {
          max-width: 300px;
        }
      }
      .header {
        font-family: 'Inter', sans-serif;
        font-size: 18px; //refactor
        font-weight: 500; //refactor
        color: #495057;
        // text-align: left;
      }
      .box1p {
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        color: #495057;
        // text-align: left;
        @media screen and (min-width: 990px) {
          margin-top: -10px;
        }
      }
      // p {
      //   font-size: 14px;
      // }
      @media screen and (min-width: 480px) {
        padding: 20px 10px 20px 20px;
      }
      @media screen and (min-width: 576px) {
        max-width: 600px;
        padding: 20px 10px 20px 50px;
      }
    }
    .mergeItems:hover {
      box-shadow: 0px 2px 0px rgb(170, 166, 166);
      transition: all 0.4s ease-in-out;
      transform: translateY(-3px);
    }
    @media screen and (min-width: 576px) {
      padding: 0px 30px;
    }
    @media screen and (min-width: 768px) {
      padding: 0px 30px;
      width: 500px;
    }
    @media screen and (min-width: 990px) {
      padding: 0px 30px;
      width: 600px;
    }
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
}
// // divider
// .divider7 {
//   margin-top: -25px;
//   z-index: 1;
//   position: relative;
// }

// section7
.section7 {
  height: 100vh;
  background-color: rgb(45, 45, 45);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  position: relative;
  div {
    max-width: 400px;
    color: rgba(255, 255, 255, 0.8) !important;
    h4 {
      font-family: 'Inter', sans-serif;
      color: #74d550 !important;
      font-size: 48px; //refactor
      font-weight: 700;
      // text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 40px;
      }
    }
    p {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
    }
    @media screen and (min-width: 768px) {
      max-width: 730px;
      // font-size: 20px;
      h4 {
        font-size: 48px; //refactor
      }
      p {
        font-size: 16px;
      }
    }
  }
}

// section8
.section8 {
  display: flex;
  background-color: rgb(248, 248, 248);
  justify-content: center;
  align-items: center;
  padding: 50px 15px;
  position: relative;
  // margin-top: 110px;
  div {
    max-width: 400px;
    h4 {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      // font-size: 30px; //refactor
      @media screen and (max-width: 400px) {
        font-size: 28px;
      }
      @media screen and (min-width: 768px) {
        font-size: 35px;
      }
      @media screen and (min-width: 993px) {
        font-size: 48px;
      }
    }
    @media screen and (min-width: 768px) {
      max-width: 750px;
    }
    @media screen and (min-width: 993px) {
      max-width: 750px;
    }
  }
  .sec8item2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: 700;
      border: 2px solid black;
      width: 100%;
      padding: 5px 0px;
      margin-top: 12px;
      align-items: center;
      display: flex;
      justify-content: center;
      // img {
      //   width: 30px;
      // }
      @media screen and (min-width: 767px) {
        width: 200px;
        margin-left: 10px;
        margin-right: 10px;
      }
      a {
        text-decoration: none;
        color: black;
      }
    }
    div:hover {
      background-color: black;
      color: white;
      fill: white;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
    div:hover a {
      color: white;
    }
  }
}
