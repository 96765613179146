.App 
{
  text-align: center;
}
@font-face {
  font-family: 'Manrope';
  src: url('../src/assets/fonts/static/Manrope-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ManropeEb';
  src: url('../src/assets/fonts/static/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../src/assets/fonts/static/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('../src/assets/fonts/static/Inter-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'InterR';
  src: url('../src/assets/fonts/static/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.foManEb{
  font-family: 'ManropeEb';
}

.foOpenSans{
  font-family: 'Open Sans';
}

.foInter{
  //refactor this line - InterEb
  font-family: 'Inter';
}

.foInter{
  font-family: 'InterR';
}