.sub-menu {
    margin: 80px auto;
    @media only screen and (max-width: 768px) {
        margin: 60px auto;
    }
}

//column
.custom-col {
    flex: 0 0 20%;
    max-width: 20%;
    @media only screen and (max-width: 768px) {  
        flex: 0 0 50%;
        max-width: 50%;
    }    
}

.footer-top {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 400px),
    (min-width: 401px) and (max-width: 768px) {
    .footer-top .container-fluid.ps-5.pe-5 { //TODO: refactor this line
     padding: 0 !important;
     }
    }
    //column modify       
    .custom-card-col{
        display: none;
        @media only screen and (max-width: 768px) {
            display: block;
        }
    }
    @media only screen and (max-width: 768px) {
        .custom-company-col, 
        .custom-discover-col,
        .custom-news-col,
        .custom-legal-col
        {
            display: none;
        }
    }
    .custom-newsletter-col {
        margin-right: 40px !important;
        @media only screen and (max-width: 400px) {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
        @media only screen and (max-width: 768px) {
            padding-left: 40px;
            padding-right: 40px;
            margin-right: 0px !important;
          }
    }    
    //card
    .custom-card-col {        
        margin-right: 40px !important;
        @media only screen and (max-width: 400px) {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
        @media only screen and (max-width: 768px) {
            margin-right: 0px !important;
            flex: auto;
            padding-left: 40px;
            padding-right: 40px;
        }
        .custom-card {
            border-width: 2px;
            padding: 10px 30px;
            @media only screen and (max-width: 768px) {
                border-radius: 10px;
          }      
        }       
        img {
            display: block;
            margin: 0 auto;
            width: 50%;
         }    
        .card-body{
            padding-top: 10px !important;
            @media only screen and (max-width: 400px) {
                padding-top: 10px !important;
            }
        }
        p.card-text{
             color: #495057!important;
             font-size: 14px;             
             margin-bottom: 16px;
            @media only screen and (max-width: 400px) {
                font-size: 12px;
            }
        }        
    }

    .heads {
        position: relative;
        color: #495057;
        margin: 5px 0;
        text-align: start; 
        font-weight: 700;
        @media only screen and (max-width: 400px) {
            padding: 0px !important;            
        }     
        @media only screen and (max-width: 768px) {    
            padding: 20px 0px;            
            text-align: center;
        }
    }
     //hover effect    
     .primary-link {
        display: inline-block;
        text-decoration: none;
        text-transform: none;
        text-align: left;
        color: #495057;
        &:hover {
            &:after {
                width: 100%;
            }
        }
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 3px;
            background: #5EC14A; 
            transition: width .3s;
        }
    }

    ul {
        margin: 0px !important;
        padding: 0px !important;
        list-style-type: none;

        li {
            text-align: start;
            margin-top: 10px;

            a {
                text-decoration: none;
                color: #757575;
                padding: 5px 0;
            }
        }
    }

    form {
        .form-group {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            input {
                height: 50px;
                background-color: #f8f9fa!important;
                border: none;
                border-radius: 5px;
                outline: none !important;
                transition: 0.4s;
                margin-top: 7px;
                text-align: center;
                @media only screen and (max-width: 400px) {
                    height: 40px;
                    margin-top: 4px;
                }
            }              
            input :focus {
                border: 2px solid #06670d;
            }

            input::placeholder {
                color: #b4b4b4;
                opacity: 1;
                font-size: 16px;
                font-family: 'Manrope';
            }

            button {
                margin: 10px 0;
                border-radius: 5px;
                box-shadow: 0 .25rem .5rem rgba(0,0,0,.05), 0 1.5rem 2.2rem rgba(0,0,0,.1) !important;
                text-align: center;
                color: white;
                height: 50px;
                margin: 0;
                margin-top: 7px;
                font-size: 16px;
                font-family: 'ManropeEb'; //TODO: refactor this line
                background: #070707;                
                @media only screen and (max-width: 400px) {
                    margin: 0;
                    padding: 0;
                    height: 40px;
                    margin-top: 4px;
                    font-size: 12px;
                }
            }
            button:disabled,
            button[disabled]{
                background: #757575 !important;
            }
        }

    }

    .hiring a {
        color: #74d550 !important;
        border-radius: 5px;
    }
    
}